










































































































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import ProfileWidget from "@/common-app/components/ProfileWidget.vue";
import { IHospitalDetail } from "../models/HospitalDetail";
import HospitalDetailService from "../service/HospitalDetailService";
import AlertService from "@/common-app/service/AlertService";
import Alert from "../../common-app/components/Alert.vue";

@Component({
  components: {
    ProfileWidget,
  },
})
export default class DiagnosticCreate extends Vue {
  @Prop({ required: true }) detailData: IHospitalDetail;

  public fee: number = 0;
  public title: string = "Sharafat";
  public hospitalDetail: IHospitalDetail = {} as IHospitalDetail;
  public submited: boolean = false;
  public isAccept: boolean = false;
  @Watch("detailData", { deep: true })
  dataGet(f: IHospitalDetail) {
    this.hospitalDetail = f;
  }

  onSubmit() {
    this.$validator.validate().then((valid) => {
      if (valid && this.isAccept) {
        this.submited = true;
        HospitalDetailService.GetInsert(this.hospitalDetail).then((res) => {
          if (res.data) {
            this.submited = false;
            this.isAccept = false;
            this.hospitalDetail = {} as IHospitalDetail;
            this.$validator.reset();
            //alert("Save");
            AlertService.sendMessage("Save successfully");
          } else {
            alert(res.data.message);
          }
        });
      }
    });
  }
}
